@import "main.css";
.fs-2.fw-700.titles.text-color-sixth {
  font-size: 3rem !important;
}

.display-6.text-center.titles.text-color-sixth {
  font-size: 3.5rem !important;
}

.my-0.p-2.fw-normal.fs-4 {
  font-size: 2rem !important;
}